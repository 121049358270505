// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

